<template>
  <list-template
    :search-config="searchConfig"
    @onSearch="onSearch"
    :table-config="tableConfig"
    :table-data="tableData"
    :total="total"
    :loading="loading"
    :current-page="page"
    @onChangePage="handleCurrentChange"
    @onHandle="handleClick"
    @onChange="onChange"
  >
  </list-template>
</template>
<script>
import { tableListMixin } from '@/utils/mixins'
import { getApi2 } from '@/api'

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  name: 'List',
  mixins: [tableListMixin],
  created() {
    this.getSearchConfig()
  },
  methods: {
    getSearchConfig() {
      getApi2('/evaluation/result/get-range').then(res => {
        const { grade, class: classList, school } = res
        this.searchConfig[0].options = school
        this.searchConfig[1].options = grade
        this.searchConfig[2].options = classList
      })
    },
    getData() {
      this.getDataApi('/api2/api/evaluation/sum-up/done-list')
    },
    handleClick(row, handle) {
      // const { type } = handle
      // if(type === 'edit' && row.draft) {
      //   this.$router.push(`./add?group_id=${row.id}&edit=1`)
      // } else if(type === 'view') {
      //   this.$router.push(`./detail?id=${row.id}`)
      // }
      if(row.draft) {
        return this.$router.push(`./add?group_id=${row.id}&edit=1`)
      }
      this.$router.push('./detail?id=' + row.id)
    }
  },
  data() {
    return {
      searchConfig: [
        {
          tag: 'select',
          placeholder: '请选择校区',
          prop: 'school_id',
          options: [],
          label: 'name',
          value: 'id',
          change: (val, search) => {
            this.search = search
            if (search.grade_id) this.search.grade_id = ''
            if (search.class_id) this.search.class_id = ''
            getApi2('/evaluation/result/get-range', { school_id: val }).then(res => {
              const { grade, class: classList } = res
              this.searchConfig[1].options = grade
              this.searchConfig[2].options = classList
            })
          }
        },
        {
          tag: 'select',
          placeholder: '请选择年级',
          prop: 'grade_id',
          options: [],
          label: 'name',
          value: 'id',
          change: (val, search) => {
            this.search = search
            if (search.class_id) this.search.class_id = ''
            const { school_id, grade_id } = search
            getApi2('/evaluation/result/get-range', { school_id, grade_id }).then(res => {
              const { class: classList } = res
              this.searchConfig[2].options = classList
            })
          }
        },
        {
          tag: 'select',
          placeholder: '请选择行政班',
          prop: 'class_id',
          options: [],
          label: 'name',
          value: 'id'
        },
        {
          tag: 'input',
          prop: 'student',
          placeholder: '输入学生姓名'
        },
        {
          tag: 'input',
          prop: 'student_no',
          placeholder: '输入学生学号'
        }
      ],
      tableConfig: [
        {
          type: 'number',
          label: '序号',
          width: '80rem',
          fixed: 'fixed',
        },
        {
          prop: 'student_name',
          label: '结案学生',
          width: '100rem',
          fixed: 'fixed',
        },
        {
          prop: 'student_no',
          label: '学号',
          width: '140rem',
          fixed: 'fixed',
        },
        {
          prop: 'start_time',
          label: '咨询开始时间',
          width: '180rem'
        },
        {
          prop: 'end_time',
          label: '咨询结束时间',
          width: '180rem'
        },
        {
          prop: 'speed',
          label: '咨询总次数',
          width: '120rem'
        },
        {
          prop: 'teacher',
          label: '咨询老师',
          width: '100rem'
        },
        {
          prop: 'class_name',
          label: '行政班',
          width: '140rem'
        },
        {
          prop: 'grade_name',
          label: '年级',
          width: '140rem'
        },
        {
          prop: 'school_name',
          label: '校区'
        },
        {
          label: '操作',
          width: '120rem',
          handle: true,
          fixed: 'right',
          buttons: (row) => row.draft ? [{ type:"edit", text:"结案" }] : [{ type:"view", text:"查看" }]
        }
      ]
    }
  }
}
</script>
